<template>
    <div class="container">
        <div class="close-btn" @click="handleClose">
            <div />
            <div />
        </div>
        <div>
          <h1>Thank you for your feedback!</h1>
          <div class="scrollable">
            <p class="info">In July 2024, we added Territories to GeoGrid (think: Christmas Island, Falkland Islands, etc.). While providing consistency with our other games (e.g. Worldle, Flagle), we also reasoned the change would enhance gameplay (more possible answers = more fun).
              But we wanted to hear directly from those who play the game regularly and know it best—you, our users. Here’s what you had to say:</p>
            <p class="question">To what extent do you support this decision?</p>
  
            <div class="weighted-avg" >3.6
              <p>Average rating</p>
            </div>
  
            <div class="chart-container" >
  
               <div class="chart-display">
                  <div v-for="(result, index) in pollResults" :key="index" class="poll-result" :style="{backgroundColor: getColor(index), height: getHeight(result) + 'px' }"><p>{{ result }}</p></div>
                  <div class="chart-line chart-line-6"><label>6k</label></div>
                  <div class="chart-line chart-line-5"><label>5k</label></div>
                  <div class="chart-line chart-line-4"><label>4k</label></div>
                  <div class="chart-line chart-line-3"><label>3k</label></div>
                  <div class="chart-line chart-line-2"><label>2k</label></div>
                  <div class="chart-line chart-line-1"><label>1k</label></div>
                  <label className="label-0">0</label>
               </div>
            </div>
            <div class="poll-labels">
              <label>Bad idea</label>
              <label>Not a good idea</label>
              <label>Neutral</label>
              <label>Good idea</label>
              <label>Great idea</label>
            </div>
            <p>In a perfect world, we would have gotten a unanimous result. But the results do strongly lean in one direction: we got 2.1x as many votes agreeing with the change as those disagreeing. As such, we feel we should stick with the move to include Territories. Thank you sincerely to all those who participated.</p>
          </div>
        </div>
    </div>
  </template>
  

  <script>
import axios from 'axios';
import config from '../axiospath.js';

  export default {
    name: "UserPoll",
    data() {
      return {
          selections: [
              'Bad idea',
              'Not a good idea',
              'Neutral',
              'Good idea',
              'Great idea'
          ],
          hoveredIndex: -1,
          selectedIndex: -1,
          state: 'initial',
          // hasVisitedBefore: false,
          // hasClosedPoll: false,
          responseMessage: '',
          pollResults: [2292,1471,1459,1993,5888],
      }
    },
    methods: {
      handleMouseOver(index) {
        this.hoveredIndex = index;
      },
      handleMouseClick(index) {
        this.selectedIndex = index;
      },
      handleMouseLeave() {
        this.hoveredIndex = -1;
      },
      async getIp() {
        try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
        } catch (error) {
          console.error('Error getting IP:', error);
          return null;
        }
      },
      async handleSubmit() {
        this.state = 'submitting';
        const ip = await this.getIp();
        if (!ip) {
          this.responseMessage = 'Failed to submit your vote. Please try again later.'
          return;
        }

        const voteData = {
          vote: this.selectedIndex,
          ip: ip,
        };

        try {
          const res = await axios.post(`${config.baseURL}/poll`, voteData);
          this.responseMessage = res.data.status === 'success' ? 'Your vote has been submitted!' : 'You have already voted.';
          if (res?.statusText === "OK") { 
            localStorage.setItem('voted', true);
            this.$emit('hide');
          }
        } catch (e) {
          console.error('Error submitting vote', e);
          this.responseMessage = 'Failed to submit your vote. Please try again later.';
        } finally {
          this.state = 'voted';
        }
      },
      handleClose() {
        localStorage.setItem('hasClosedPoll', true);
        this.$emit('close');
      },
      getColor(index) {
        if (index === 0) return 'rgb(220, 53, 69)';
        if (index === 1) return 'rgb(255, 133, 27)';
        if (index === 2) return 'rgb(255, 193, 7)';
        if (index === 3) return 'rgb(166, 184, 44)';
        if (index === 4) return 'rgb(76, 175, 80)';
      },
      getStyleIndex() {
        if (this.hoveredIndex !== -1) return this.hoveredIndex;
        else return this.selectedIndex;
      },
      getHeight(voteCount) {
        const screenWidth = window.innerWidth;
        const pollHeight = screenWidth > 480 ? 400 : 300; // 400px = 10k votes
        const multiplier = voteCount / 10000;
        return pollHeight * multiplier;
      }
    },
    computed: {
        dynamicText() {
            if (this.hoveredIndex === -1 && this.selectedIndex === -1) {
                return '\u00A0';
            } else if (this.hoveredIndex > -1) {
                return this.selections[this.hoveredIndex];
            } else {
                return this.selections[this.selectedIndex];
            }
        }
    }
  };
  </script>
  

  <style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .container {
    background: #2a2a3a;
    width: 900px;
    color: white;
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 24px;
    padding-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    animation: slideInFromTop 1s;
    max-height: 95%;
  }
  .scrollable{
    height: 80vh;
    overflow: auto;
  }
  .info {
    margin: 1.5rem 0 2rem;
    /* background: rgba(0,0,0,0.2);
    padding: 4px 0;
    border-radius: 4px; */
  }
  .question{
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .rating-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  .option {
    width: 50px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid;
    border-top-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.4);
    border-right-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .highlight {
    background-color: v-bind(getColor(getStyleIndex()));
  }
  .label{
    margin-top: 16px;
  }
  .btn{
    background: rgba(255,255,255,0.12);
    border: none;
    border-radius: 4px;
    color: white;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn:disabled:hover{
    background: rgba(255,255,255,0.12);
  }
  .btn:not(:disabled):hover{
    background: rgba(255,255,255,0.2);
  }
  .btn:disabled{
    opacity: 0.5;
    cursor: default;
  }
  .close-btn{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  .close-btn > *{
    background: rgb(220, 53, 69);
    width: 18px;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
  }
  .close-btn > *:first-child{
    transform: rotateZ(45deg) ;
  }
  .close-btn > *:last-child{
    transform: rotateZ(-45deg);
  }


  .chart-container{
    box-sizing: content-box;
    background: rgba(0,0,0,0.5);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;
    margin: 0 30px;
    border-left: 2px solid rgba(255,255,255,0.3);
    border-bottom: 2px solid rgba(255,255,255,0.3);
  }

  .chart-display{
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 5%;
    width: 100%;
  }

  .poll-result{
    width: 14%;
    position: relative;
    z-index: 2;
    font-size: 15px;
  }

  .poll-result p{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .chart-line{
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    background-color: rgba(255,255,255,0.2);
    z-index: 1;
  }
  .chart-line label{
    position: absolute;
    left: -25px;
    top: -15px;
    font-size: 15px;
  }
  .chart-line-1{
    bottom: calc(0.1 * 400px);
  }
  .chart-line-2{
    bottom: calc(0.2 * 400px);
  }
  .chart-line-3{
    bottom: calc(0.3 * 400px);
  }
  .chart-line-4{
    bottom: calc(0.4 * 400px);
  }
  .chart-line-5{
    bottom: calc(0.5 * 400px);
  }
  .chart-line-6{
    bottom: calc(0.6 * 400px);
  }
  .label-0{
    position: absolute;
    bottom: -12px;
    left: -25px;
    font-size: 15px;
  }

  .poll-labels{
    margin: 6px 30px 24px;
    display: flex;
    justify-content: center;
    gap: 5%;
    font-size: 15px;
  }

  .poll-labels label{
    width: 14%;
  }

  .weighted-avg{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .weighted-avg p{
    font-size: 12px;
    color: rgba(255,255,255,0.5);
    margin-top: -10px;
  }

  @media screen and (max-width: 992px) {
    .container{
      width: 750px;
    }
  }
  @media screen and (max-width: 768px) {
    .container{
     width: 85%;
     font-size: .85rem;
    }
    .btn{
        font-size: .85rem;
    }
    .weighted-avg{
      font-size: 1.8rem;
    }
  }
  @media screen and (max-width: 480px) {
    .container{
        width: 90%;
        padding-top: 30px;
    }
    h1{
        font-size: 1.3rem;
    }
    .info{
        font-size: 13px;
    }
    .rating-container{
        gap: 5px;
    }
    .option{
        font-size: 12px;
        width: 40px;
        height: auto;
    }
    .btn{
        font-size: 12px;
    }
    .close-btn{
        width: 30px;
        height: 30px;
        top: 3px;
        right: 3px;
    }
    .close-btn > *{
        left: 8px;
        width: 14px;
    }
    .chart-line label{
      font-size: 13px;
    }
    .label-0{
      font-size: 13px;
    }
    .poll-result p{
      font-size: 12px;
      top: -20px;
    }
    .chart-container{
      height: 225px;
      margin-bottom: 30px;
    }
    .chart-line-1{
      bottom: calc(0.1 * 300px);
    }
    .chart-line-2{
      bottom: calc(0.2 * 300px);
    }
    .chart-line-3{
      bottom: calc(0.3 * 300px);
    }
    .chart-line-4{
      bottom: calc(0.4 * 300px);
    }
    .chart-line-5{
      bottom: calc(0.5 * 300px);
    }
    .chart-line-6{
      bottom: calc(0.6 * 300px);
    }
    .poll-labels{
      display: none;
    }
    .weighted-avg{
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 375px) {
    .option{
        width: 30px;
        height: 30px;
    }
    .btn{
        height: 30px;
    }
  }
  @keyframes slideInFromTop {
    0% {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
}
  </style>
  
